<template>

    <v-card>
        <v-container grid-list-xs>
            <v-col>
                <v-row class="mb-3">
                    <h2 style="color:#000;padding-right: 10px;">معلومات الحجز</h2>
                </v-row>
                <v-divider class="mb-3"></v-divider>
                <v-row>
                    <v-col cols="6">

                        <v-row class="mb-2 mr-2 ">
                            <v-icon size="25" style="padding-left:5px">fas fa-bookmark</v-icon>
                            <label>اسم المركز</label>
                        </v-row>


                        <v-row class="mb-2 mr-2 mt-4">
                            <v-icon size="25" style="padding-left:5px">fas fa-calendar-day</v-icon>
                            <label> تاريخ الحجز</label>
                        </v-row>

                        <v-row class="mb-2 mr-2 mt-4">
                            <v-icon size="25" style="padding-left:5px">fas fa-clock</v-icon>
                            <label> الوقت</label>
                        </v-row>


                        <v-row class="mb-2 mr-2 mt-4">
                            <v-icon size="25" style="padding-left:5px">fas fa-clock</v-icon>
                            <label>حاله الحجز</label>
                        </v-row>






                        <v-row class="mb-2 mr-2 mt-4">
                            <v-icon size="25" style="padding-left:5px">fas fa-dollar-sign</v-icon>
                            <label>السعر</label>

                        </v-row>


                        <!-- <v-row class="mb-2 mr-2 mt-4">
                            <v-icon size="25" style="padding-left:5px">fas fa-money-check-alt</v-icon>
                            <label> طريقه الدفع</label>
                        </v-row> -->




                        <v-row class="mb-2 mr-2 mt-4" v-if="BookingInfo.feature.length!==0">
                            <!-- <v-icon size="20" style="padding-left:5px">fas fa-money-check-alt</v-icon> -->
                            <label>ميزات اضافيه</label>


                        </v-row>

                        <v-row v-if="BookingInfo.owner_notes !== null" class="mb-2 mr-2 mt-4">
                            <v-icon size="25" style="padding-left:5px">fas fa-comment-alt</v-icon>
                            <label> ملاحظات المركز</label>

                        </v-row>

                        <v-row class="mb-2 mr-2 mt-4" v-if="BookingInfo.ReservationRequirements.length>0">
                            <v-icon size="25" style="padding-left:5px">fas fa-money-check-alt</v-icon>
                            <label>الصور المرسله </label>
                        </v-row>








                    </v-col>

                    <v-col cols="6">
                        <v-row class="mb-2 "><label> {{BookingInfo.item.item_name}} </label></v-row>
                        <v-row class="mb-2 mt-4"><label> {{BookingInfo.reservation_start_date}}</label></v-row>
                        <v-row class="mb-2 mt-4"><label>
                                {{formatAMPM(BookingInfo.reservation_from_time)}}
                            </label>

                        </v-row>

                        <v-row class="mb-2 mt-4"><label>

                                <v-chip class="mb-4 mobstyle" dence dark="" small
                                    :color="BookingInfo.status.status_color">
                                    <span style="color:#fff"> {{BookingInfo.status.status_name_ar}}
                                        <v-icon left size="12"> {{BookingInfo.status.status_icon}}</v-icon>
                                    </span>

                                </v-chip>

                            </label>

                        </v-row>





                        <v-row class="mb-2 ">
                            <label>
                                {{BookingInfo.bill.amount}} د.ع

                            </label>

                        </v-row>



                        <v-row class="mb-2 mt-4" v-for="feature  in  BookingInfo.feature" :key="feature">
                            <label>
                                {{feature.item_feature.feature_name}}

                            </label>

                        </v-row>


                        <v-row class="mb-2 mt-4" v-if="BookingInfo.owner_notes !== null">
                            <label>
                                {{BookingInfo.owner_notes}}

                            </label>

                        </v-row>





                    </v-col>
                    <v-row class="mb-2 mt-4" v-if="BookingInfo.ReservationRequirements.length>0">
                        <label>

                            <img :src="Url+'/images/'+BookingInfo.ReservationRequirements[0].image.image_url"
                                style="width:300px;hright:300px"><img>


                        </label>

                    </v-row>
                </v-row>




                <v-spacer></v-spacer>


            </v-col>



        <!-- <v-card flat color="#fff"> -->
          <v-row>
                   
                   <v-flex xs2>
                         <v-btn dark="" color="red" class=" bt_cancel_reserv" v-if="BookingInfo.status.id==4"
                        @click="change_reservation_status(BookingInfo.id,8)">
                        <v-icon right="" class="mb_resposiv_hidden">fas fa-times</v-icon>
                        <span class="font_button">
                            الغاء حجز
                        </span>
                    </v-btn>
                   </v-flex>
                     <v-flex xs8></v-flex>
                       <v-flex xs2>
                            <v-btn style="color:#fff"  class="float-left" color="red"  @click="close()">خروج</v-btn>
                       </v-flex>
                  
              
                   
               
          </v-row>

        <!-- </v-card> -->

        </v-container>
    </v-card>

</template>


<script>
    export default {
        props: {

            'BookingInfo': Object,
        },
        data: () => ({
            user_info: {},


        }),




        mounted() {


        },


        methods: {

            close() {
                this.$emit("changeStatus",true)
            },
            formatAMPM(date) {
                if (typeof date === "string") {
                    let [hours, minutes] = date.split(":");
                    let ampm = "ص";

                    if (Number(hours) > 12) {
                        hours = Number(hours) - 12;
                        ampm = "م";
                    }

                    return `${hours}:${minutes} ${ampm}`;

                } else if (date instanceof Date) {
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    let ampm = hours >= 12 ? 'م' : "AM";

                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? "0" + minutes : minutes;

                    let strTime = hours + ":" + minutes + " " + ampm;

                    return strTime;
                }

                return date;
            },
            change_reservation_status(item_id, item_status) {



                var data = {
                    reservation_id: item_id,
                    status_id: item_status
                };
                const Swal = require('sweetalert2')

                Swal.fire({
                    title: "هل انت متاكد ",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم'

                }).then((result) => {
                    // if (result.value) {

                    result,
                    // this.loding_accept = true;
                    this.$http({
                        method: 'post',
                        url: "/reservation/user/changeStatus",
                        data: data,
                        headers: {

                        }
                    })
                    .then(
                        //this.getitems(),
                        //this.getitems(),
                        // location.reload(),
                        // this.dialog = false,
                        //this.loding_accept = false,
                        this.$emit("changeStatus", true),


                        // swal("تمت الموافقة", {
                        //     icon: "success",
                        // })




                    )
                    .catch(error => {
                            error
                            ///   this.getitems();


                        }

                    ).finally(v => {
                            v
                            // this.getitems(),
                            //this.loding_accept = false

                        }


                    );



                    //}
                    //  this.getitems();
                })





            },
        }

    }
</script>