<template>
  <v-container >
    <v-dialog @changeStatus="BookingDetails=false" v-model="BookingDetails" width="600">

      <BookingDetails @changeStatus="BookingDetails=false;getMyreserById()" :BookingInfo="BookingDetailsInfo">

      </BookingDetails>


    </v-dialog>

    <v-layout column="" wrap justify-center="" class="mybooking" >

   
<!--    
      <v-row class="mb-4">
        <v-divider></v-divider>

      </v-row> -->



   
        <v-container >
          <v-layout  wrap row  style="min-height: 800px;">

            <!-- profile part -->
            <v-flex xs12 xl2 md3 sm3 pa-2>
              <v-card style="padding:15px">
                <v-container>

                  <v-layout column="">

                    <!-- <v-row justify="center" class="mb-6">
                      <v-avatar size="80">
                        <img :src="this.$cookies.get('user_info').photo">
                      </v-avatar>
                    </v-row> -->

                    <!-- <v-row justify="center" class="mb-2">
                      <label style="color:gary">{{this.$cookies.get('user_info').name}}@</label>

                    </v-row> -->

                    <!-- <v-divider class="mb-6"> </v-divider>
 -->

                    <!-- <v-row class="mb-4">

                      <v-icon style="margin-left:10px;" color="#65A1A7">fas fa-user</v-icon>
                      <label>{{this.$cookies.get('user_info').name}}</label>

                      <v-spacer></v-spacer>
                      <v-icon style="margin-left:30px;" color="gray" size="15">fas fa-pen</v-icon>
                    </v-row> -->

                    <!-- <v-row class="mb-4">

                      <v-icon style="margin-left:10px;" color="#65A1A7">fas fa-envelope</v-icon>
                      <label>{{this.$cookies.get('user_info').email}}</label>
                      <v-spacer></v-spacer>
                      <v-icon style="margin-left:30px;" color="gray" size="15">fas fa-pen</v-icon>
                    </v-row> -->

                    <!-- <v-row class="mb-6">

                      <v-icon style="margin-left:10px;" color="#65A1A7">fas fa-phone-square</v-icon>
                      <label>{{this.$cookies.get('user_info').phone}}</label>
                      <v-spacer></v-spacer>
                      <v-icon style="margin-left:30px;" color="gray" size="15">fas fa-pen</v-icon>
                    </v-row> -->
                    <!-- <v-divider class="mb-4"></v-divider> -->
                    <v-row>

                   

                        <v-row class="mb-6">

                          <v-list style="width:100%">

                            <v-list-item-group color="gray">
                              <v-list-item v-for="(item, i) in filterTitile" :key="i" :inactive="inactive">

                                <v-list-item-content @click="ontap(i)" check>
                                  <v-row style="margin-right:1%">
                                    <v-icon style="margin-left:20px;" color="#65A1A7">{{item.icon}}</v-icon>
                                    <label> {{item.name}}</label>
                                  </v-row>

                                </v-list-item-content>
                              </v-list-item>
                              <!-- 
                              <v-list-item :inactive="inactive">

                                <v-list-item-content @click="mybills()" check>
                                  <v-row style="margin-right:1%">
                                    <v-icon style="margin-left:20px;" color="#65A1A7">fas fa-money-check-alt</v-icon>
                                    <label> فواتيري</label>
                                  </v-row>

                                </v-list-item-content>
                              </v-list-item> -->

                            </v-list-item-group>
                          </v-list>





                        </v-row>
                  
                    </v-row>

                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>




            <!-- part of items -->

            <v-flex xs12 xl6 md7 sm7 pa-2 v-if="loading">
              <v-flex v-for="a in 4" pa-3 :key="a">

                <v-skeleton-loader ref="skeleton" type="article" class="mx-auto"></v-skeleton-loader>
              </v-flex>

            </v-flex>


            <v-flex xs12 xl6 md7 sm7 pa-2 v-else>
             
                <v-layout column wrap v-if="datafound" >

                  <v-card class="bookingcard" v-for="item in items" :key="item" style="margin-bottom:30px">



                    <v-hover v-slot:default="{ hover }">
                      <!-- <mybookingCard :item="item"  :index="index" :token="token"></mybookingCard> -->

                      <v-layout row wrap>


                        <v-flex xs12>
                          <!-- <router-link :to="{ name: 'item', params: { id: item.item.id } }"> -->
                       
                       <v-layout row wrap>

                       <v-flex xs8 pr-1>
                              <v-card-text class="bookingcard-head ">
                            {{item.item.item_name}}   
                          </v-card-text>




                          <v-chip class="mb-4 mobstyle" dark="" :color="item.status.status_color">
                            <span style="color:#fff"> {{item.status.status_name_ar}}
                              <v-icon left size="12"> {{item.status.status_icon}}</v-icon>
                            </span>

                          </v-chip>

                       </v-flex>


 <v-flex xs4>
    <v-btn text  class=" bt_cancel_reserv" @click="BookingDetailsFun(item)">

                                <span class="font_button" style="font-weight:bold">
                                معلومات الحجز
                                   
                                </span>
                              </v-btn>
 </v-flex>

                       </v-layout>



                          <v-divider></v-divider>





                          <v-layout row wrap justify-start>


                            <v-flex xs4 pa-3 class="mb_resposiv_hidden">
                              <v-card-text class="bookingcard-title">تاريخ الحجز</v-card-text>
                              <v-card-text class="bookingcard-text"> {{item.reservation_start_date}} </v-card-text>

                            </v-flex>



                            <v-flex xs3 pa-3 class="mb_resposiv_hidden">
                              <v-card-text class="bookingcard-title">وقت الحجز</v-card-text>
                              <span>
                                <v-card-text class="bookingcard-text"> {{formatAMPM(item.reservation_from_time)}}   
                                </v-card-text>
                              </span>
                              <!-- <span v-if="item.is_valid==1"> <v-card-text class="bookingcard-text">  متبقي {{getDateDiff(item.to_date,item.to_time).hours}} ساعه</v-card-text> </span>
                               <span v-else>تم  احتياز الحجز </span> -->
                            </v-flex>




                            <v-flex xs3 pa-3 class="mb_resposiv_hidden">
                              <v-card-text class="bookingcard-title">
                                حاله الدفع 
                              </v-card-text>

                              <v-card-text class="bookingcard-text">
                                <div v-if="item.item.price.payment_when_receiving==3">


                                  دفع عند الوصول
                                </div>

                                <div v-else-if="item.bill.status_id==32">


                                  <div
                                    v-if="item.item.price.is_zaincash_payment==true && item.item.price.is_tasdid_payment==false">


                                    <v-btn color="blue" @click="zainChash(item.id)">
                                      <i v-show="!loadingZainCash" style="color:#fff"
                                        class="fas fa-money-bill-wave-alt"></i>
                                      <v-progress-circular indeterminate color="white" v-show="loadingZainCash">
                                      </v-progress-circular>

                                      <span style="color:#fff">   زين كاش</span>

                                    </v-btn>
                                    


                                  </div>


                                  <div
                                    v-else-if="item.item.price.is_zaincash_payment==false && item.item.price.is_tasdid_payment==true">
                                    <v-btn color="success" @click="PaymenyDialog=true;tasdidBill.res_id=item.id">
                                      <i class="fas fa-money-bill-wave-alt"></i>


                                         فاتوره تسديد
                                    </v-btn>


                                  </div>

                                  <div
                                    v-else-if="item.item.price.is_zaincash_payment==true && item.item.price.is_tasdid_payment==true">
                                    <v-btn color="success"
                                      @click="PaymenyDialog=true;AllPaymentMethod=true;itemPayId=item.id">
                                      <i class="fas fa-money-bill-wave-alt"></i>
                                      دفع الان
                                    </v-btn>
                                  </div>

                                </div>


                                <div v-else-if="item.bill.status_id==31">
                                  <v-chip class="mb-4 mobstyle" dark="" :color="green">
                                    <span style="color:#fff"> تم الدفع</span>
                                    <!--   <v-icon left size="12"> {{item.status.status_icon}}</v-icon>
                            </span> -->

                                  </v-chip>

                                </div>


                                <!-- <span v-else>
                                    <p>كاش</p>
                                  </span> -->


                              </v-card-text>
                            </v-flex>


                            <!-- for mobile -->
                            <v-flex xs3 class="mb_resposiv_show">
                              <v-card-text class="bookingcard-title">تاريخ الحجز</v-card-text>
                              <v-card-text class="bookingcard-text"> {{item.reservation_start_date}} </v-card-text>
                            </v-flex>



                            <v-flex xs3 class="mb_resposiv_show">
                              <v-card-text class="bookingcard-title">وقت الحجز</v-card-text>
                              <v-card-text class="bookingcard-text"> {{item.reservation_from_time}} </v-card-text>
                            </v-flex>




                            <v-flex xs6  class="mb_resposiv_show">
                              <v-card-text class="bookingcard-title">
                                حاله الدفع 
                              </v-card-text>

                              <v-card-text class="bookingcard-text">
                                <div v-if="item.item.price.payment_when_receiving==3">
                                  دفع عند الوصول
                                </div>

                                <div v-else-if="item.bill.status_id==32">


                                  <div
                                    v-if="item.item.price.is_zaincash_payment==true && item.item.price.is_tasdid_payment==false">


                                    <v-btn color="blue" @click="zainChash(item.id)">
                                      <i v-show="!loadingZainCash" style="color:#fff"
                                        class="fas fa-money-bill-wave-alt"></i>
                                      <v-progress-circular indeterminate color="white" v-show="loadingZainCash">
                                      </v-progress-circular>

                                      <span style="color:#fff">   زين كاش</span>

                                    </v-btn>


                                  </div>


                                  <div
                                    v-else-if="item.item.price.is_zaincash_payment==false && item.item.price.is_tasdid_payment==true">
                                    <v-btn color="success" @click="PaymenyDialog=true;tasdidBill.res_id=item.id">
                                      <i class="fas fa-money-bill-wave-alt"></i>


                                         فاتوره تسديد
                                    </v-btn>


                                  </div>

                                  <div
                                    v-else-if="item.item.price.is_zaincash_payment==true && item.item.price.is_tasdid_payment==true">
                                    <v-btn color="success"
                                      @click="PaymenyDialog=true;AllPaymentMethod=true;itemPayId=item.id">
                                      <i class="fas fa-money-bill-wave-alt"></i>
                                      دفع الان
                                    </v-btn>
                                  </div>

                                </div>


                                <div v-else-if="item.bill.status_id==31">
                                  <v-chip class="mb-4 mobstyle" dark="" :color="green">
                                    <span style="color:#fff"> تم الدفع</span>
                                    <!--   <v-icon left size="12"> {{item.status.status_icon}}</v-icon>
                            </span> -->

                                  </v-chip>

                                </div>


                                <!-- <span v-else>
                                    <p>كاش</p>
                                  </span> -->


                              </v-card-text>
                            </v-flex>



                            <!-- end mobile ui -->
                          </v-layout>
                          <!-- </router-link> -->
                        </v-flex>






                      
                      </v-layout>

                    </v-hover>

                  </v-card>
                  <v-row justify="center" style="width:100%">
                    <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle=""
                      :length="pageCount">
                    </v-pagination>

                  </v-row>


                </v-layout>




              

              <v-card v-else class="mb_emptyreservation" outlined="">
                <v-container>
                  <v-row style="padding:20px">
                    <v-icon size="50" right="" color="warning" style="margin-left:20px">fas fa-exclamation-circle
                    </v-icon>
                    <h1 style="color:gray">لاتوجد حجوزات</h1>
                  </v-row>
                </v-container>
              </v-card>

            </v-flex>







          </v-layout >
        </v-container>
    

    </v-layout>

    <v-dialog v-model="PaymenyDialog" width="500px" height="500px">
      <v-card color="#fff" backgroundcolor="#fff">
        <v-layout row wrap>
          <div style="padding:18px">
            طريقه الدفع
          </div>
          <!-- <div v-if="item.item.price.is_zaincash_payment==0 && item.item.price.is_tasdid_payment==1"> -->

          <div v-if="AllPaymentMethod">

            <v-layout row wrap pa-5>
              <v-flex xs12 pa-5>
                <v-btn color="blue" @click="zainChash(itemPayId)">
                  <i v-show="!loadingZainCash" style="color:#fff" class="fas fa-money-bill-wave-alt"></i>
                  <v-progress-circular indeterminate color="white" v-show="loadingZainCash"></v-progress-circular>

                  <span style="color:#fff">دفع عن طريق زين كاش</span>

                </v-btn>







              </v-flex>


              <v-flex xs12 pa-5>





                <v-btn color="success" @click="PaymenyDialog=true;tasdidBill.res_id=itemPayId;AllPaymentMethod=false">
                  <i class="fas fa-money-bill-wave-alt"></i>


                  دفع عن طريق فاتوره تسديد
                </v-btn>

              </v-flex>


            </v-layout>


            <v-layout>
              <v-flex xs10></v-flex>
              <v-flex xs2>
                <v-btn @click="PaymenyDialog=false" color="red" style="color:#fff">خروج</v-btn>
              </v-flex>
            </v-layout>
          </div>


          <div v-else>
            <v-form ref="form_fa" v-model="valid_pay">


              <v-divider></v-divider>

              <v-card-title style="font-size:font-size: 18px;">
                ويه تسديد صار تكدر تدفع فاتورتك وانت بالبيت من خلال رقم الفاتورة الي يوصلك برسالة ..
                والتسديد من
                خلال ادخال رقم بطاقة الدفع الكي كارد أوالماستر كارد
              </v-card-title>
              <v-divider></v-divider>


              <v-layout row wrap>
                <v-flex pa-3>

                  <v-text-field outlined dense placeholder="ادخل رقم هاتفك لانشاء فاتوره" v-if="!withoutBills"
                    append-icon="fas fa-phone" type="number" :rules="numRules" required v-model="tasdidBill.phone">
                  </v-text-field>
                </v-flex>

              </v-layout>

              <v-layout row wrap pb-4 pr-4>
                <v-flex xs3 pr-3>
                  <v-btn color="success" style=";width:130px" @click="CreateBill()">
                    <i v-show="!loadingTasdid" class="fas fa-money-bill-wave-alt"></i>
                    <v-progress-circular indeterminate color="white" v-show="loadingTasdid"></v-progress-circular>

                    <span v-show="!loading">انشاء فاتوره</span>
                    <!-- <span v-show="!loading">انشاء فاتوره</span>
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular> -->

                  </v-btn>


                </v-flex>
              </v-layout>



              <!-- <div v-else></div>
 -->


            </v-form>
          </div>
          <!-- 
        </div> -->

        </v-layout>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  //const swal = require('sweetalert2');
  import {
    EventBus
  } from '../event-bus.js';
  import {
    USER_ROLE_GUEST,
  } from "../constants.js";
  import BookingDetails from './sub_components/BookingDetails.vue'
  export default {
    name: 'App',
    data: () => ({

      items: [],
      form_fa: false,
      tasdidDailog: false,
      AllPaymentMethod: false,
      loadingZainCash: false,
      loadingTasdid: false,
      tasdidBill: {
        phone: '',
        res_id: ''
      },
      PaymenyDialog: false,
      BookingDetailsInfo: {},
      itemPayId: '',
      BookingDetails: false,
      user_image: localStorage.getItem('user_image'),
      datafound: true,
      filterTitile: [{
          name: 'الكل',
          icon: 'fas fa-home'
        }, {
          name: 'الحجوزات المقبولة',
          icon: 'fas fa-check'
        },
        {
          name: 'الحجوزات المرفوضة ',
          icon: 'fas fa-window-close'
        }, {
          name: 'الحجوزات المعلقة',
          icon: 'fas fa-clock'
        }
      ],
      inactive: false,
      loading: true,
      taps_chaked: 3,
      page: 1,
      pageCount: 0,
      StatusId: '',
      current_page: 1,
      last_page: 0,
      zainCashUrl: '',

      user_info: {},
      num_cancel: -1,
      alert_msg: false,
      bills: [],
      my_bills: false,


    }),
    watch: {
      selected: 'search by sub_cat_id',
    },
    computed: {
      selected: function () {


        return this.getMoreitems();

      }
    },

    methods: {
      CreateBill() {

        this.loadingTasdid = true;

        // this.tasdidBill.CreateBill=res_id;
        if (this.$refs.form_fa.validate()) {



          this.$http({
              method: 'post',
              url: "/Payment/Tasdid/Pay",
              data: this.tasdidBill,
              headers: {

              }

            })
            .then(response => {
              response
              window.open('https://pay.tasdid.net', '_blank');
              //  this.tasdidBill={}
              this.PaymenyDialog = false;
              this.loadingTasdid = false;



            })

            .catch(error => {
              error;
              this.PaymenyDialog = false
              window.open('https://pay.tasdid.net', '_blank');

              //   
            }).finally(d => {
              d
              this.PaymenyDialog = false
              window.open('https://pay.tasdid.net', '_blank');

            })
        }

      },

      zainChash(res_id) {

        this.loadingZainCash = true,

          this.$http({
            method: 'get',
            url: "Payment/ZainCash/Pay/" + res_id,
            headers: {

            }

          })
          .then(response => {

            this.zainCashUrl = response.data.url;
            this.loadingZainCash = false

            window.open(this.zainCashUrl, '_blank')
          })

          .catch(error => {
            error;

            //   
          }).finally(d => {
            d;



          });

        //window.open(this.zainCashUrl, '_blank');
        //  return this.zainCashUrl;


      },
      formatAMPM(date) {
        if (typeof date === "string") {
          let [hours, minutes] = date.split(":");
          let ampm = "ص";

          if (Number(hours) > 12) {
            hours = Number(hours) - 12;
            ampm = "م";
          }

          return `${hours}:${minutes} ${ampm}`;

        } else if (date instanceof Date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();

          let ampm = hours >= 12 ? 'م' : "AM";

          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;

          let strTime = hours + ":" + minutes + " " + ampm;

          return strTime;
        }

        return date;
      },
      getMoreitems() {

        if (this.current_page <= this.last_page) {
          this.current_page = this.page;

          return this.getMyreserById()

        }



      },
      mybills() {

        // this.my_bills = true;

        // //  this.loading = true;
        // var url = "user/getBills/1";
        // this.$http({
        //   method: 'get',
        //   url: url,
        //   headers: {

        //   }

        // }).then(response => {
        //   response
        //   this.bills = response.data.data.data;


        //   // concel.log(response)

        // }).catch(error => {
        //   error
        // }).finally(

        //   //  this.loading = false

        // );
      },


      BookingDetailsFun(item) {
        this.BookingDetails = true;
        this.BookingDetailsInfo = item;
      },

      ontap(index) {
        switch (index) {
          case 0:
            this.items = [];
            this.StatusId = ' ';
            this.getMyreserById();
            break;
          case 1:
            this.items = [];
            this.StatusId = 6;
            this.getMyreserById();
            break;
          case 2:
            this.items = [];
            this.StatusId = 9;
            this.getMyreserById();
            // this.getMyreserById(4);
            break;
          case 3:
            this.items = [];
            this.StatusId = 4;
            this.getMyreserById();
            break;

        }


      },







      // },

      getDateDiff(to_date, to_time) {


        var today = new Date();
        var from_time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
        to_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var timediff = require('timediff');

        var DateDiff = timediff('' + to_date + '  ' + from_time + '', '' + to_date + '  ' + to_time + '', 'YDHms');


        return DateDiff;
        // if(this.today<this.to_date)
        // {
        //    return DateDiff;
        // }


      },

      getMyreserById() {
        this.my_bills = false;
        this.loading = true;

        var url = "/reservation/user/search?filter[status_id]=" + this.StatusId + "&page=" + this.current_page;
        this.$http({
          method: 'get',
          url: url,
          headers: {

          }

        }).then(response => {


          this.items = response.data.data;
          this.last_page = response.data.meta.last_page;
          this.pageCount = response.data.meta.last_page;
          window.scrollTo(0, 0)
          this.alert_msg = false;
          this.loading = false;

          if (this.items.length < 1) {

            this.datafound = false;
          } else {
            this.datafound = true;
          }
        }).catch(error => {
          this.msg = "يجب تسجيل الدخول"
          this.alert_msg = true;
          if (error.response.status == 402) {
            this.logout();
            //this.$router.reload()
          }

        }).finally(

          //    this.loading = false

        );
      },







      getDiscription(disc, len) {
        var text = disc;

        text = text.substring(0, len)


        return text;
      },

      logout() {
        this.$user.set({
          role: USER_ROLE_GUEST
        });

        window.localStorage.clear();

      }
    },
    mounted() {

      this.getMyreserById();



    },
    components: {
      BookingDetails

      //mybookingCard

    },
    created() {

EventBus.$on('closedialog', (from) => {
              from,
     // alert('da');
              this.BookingDetails=false;
          

            });

      EventBus.$on('changeStatus', (status) => {
        status,


        //
        this.getMyreserById();
        this.getMyreserById();

      });




    },


  };
</script>